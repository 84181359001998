
import { defineComponent, PropType, reactive, watch } from "vue";
import OrderList from "primevue/orderlist";
import router from "@/router";
import { RouterName } from "@/models/common";
import { isArray } from "lodash";
import Card from "primevue/card";
import { useI18n } from "vue-i18n";
export default defineComponent({
  name: "Orders",
  props: {
    list: {
      type: Array as PropType<Array<Object>>,
      required: true,
    },
    isLastOne: {
      type: Boolean,
      required: false,
    },
  },
  setup(props) {
    const state = reactive({
      selection: null as { requestKey: string } | null,
      list: props.list,
      // 마지막 결제 하나만 표시 여부
      isLastOne: props.isLastOne ?? false,
    });
    const { t } = useI18n({ useScope: "global" });
    watch(
      () => state.selection,
      (n) => {
        if (!isArray(n)) return;
        const id = n[0]?.requestKey ?? -1;
        if (id !== -1)
          router.push({
            name: RouterName.ORDER_VIEW,
            params: { id },
          });
      }
    );
    return { state, t };
  },
  components: {
    OrderList,
    Card,
  },
});
