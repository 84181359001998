
import { defineComponent, reactive } from "vue";
import router from "@/router";
import { IDataNameFilter } from "@/models/common";
import { isArray } from "lodash";
import BackButton from "@/components/common/BackButton.vue";
import DateNameFilter from "@/components/common/DateNameFilter.vue";
import DateFormat from "@/utils/date-format";
import Orders from "@/components/common/Orders.vue";
export default defineComponent({
  name: "Order List",
  setup() {
    const state = reactive({
      list: [
        {
          requestKey: "1000",
          paymentDate: "2021-07-27",
          price: 65,
          category: "온라인케어",
          requestStatus: "결재완료",
          doctorName: "김의사",
        },
        {
          requestKey: "1001",
          paymentDate: "2021-07-27",
          price: 72,
          category: "세컨드오피니언",
          requestStatus: "회신완료",
          doctorName: "박의사",
        },
      ],
    });
    const query = router.currentRoute.value.query;

    const filterState: IDataNameFilter = reactive({
      from:
        !isArray(query.from) && query.from
          ? query.from.toString()
          : DateFormat.getStringSetMonth(-1),
      to:
        !isArray(query.to) && query.to?.toString()
          ? query.to
          : DateFormat.getStringNow(),
      name: !isArray(query.name) && query.name ? query.name.toString() : "",
    });

    const onSearch = (filterState: IDataNameFilter) => {
      console.log(filterState);
    };

    return { filterState, onSearch, state };
  },
  components: {
    BackButton,
    DateNameFilter,
    Orders,
  },
});
