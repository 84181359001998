
import { defineComponent, ref } from "vue";
import Button from "primevue/button";
import router from "@/router";
import { useI18n } from "vue-i18n";

export default defineComponent({
  name: "Back Button",
  props: {
    useIcon: {
      type: Boolean,
      required: false,
    },
  },
  setup(props) {
    const { t } = useI18n({ useScope: "global" });

    /**
     * back button click event
     */
    const back = () => {
      router.back();
    };
    const isIcon = ref(!!props.useIcon);

    return { back, t, isIcon };
  },
  components: {
    Button,
  },
});
